<script setup lang="ts">
import type { WatchStopHandle } from 'vue';

const { $googleMaps, $geolocation } = useNuxtApp();

const isShowing = ref(false);
let watcher: WatchStopHandle | null = null;
const show = ref(false);
const mapEl = ref<HTMLElement | null>(null);
let googleMapInstance: google.maps.Map | null = null;
let marker: google.maps.Marker | null = null;
let circle: google.maps.Circle | null = null;
const address = ref('');

async function initMap() {
    if (mapEl.value === null) {
        throw new Error('mapEl must be initialized.');
    }
    if (googleMapInstance == null) {
        googleMapInstance = await $googleMaps.initMap(mapEl.value);
    }
    googleMapInstance.setOptions({
        disableDefaultUI: true,
    });
    marker = new (await $googleMaps.lib.marker).Marker({
        map: googleMapInstance,
    });
    circle = new (await $googleMaps.lib.maps).Circle({
        map: googleMapInstance,
        strokeColor: '#b93e3e',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#b93e3e',
    });
    updateLocation();
}

async function updateLocation() {
    if (!marker || !circle) {
        console.error('marker and circle must be initialized.');
        return;
    }
    const p = new google.maps.LatLng($geolocation.coords.latitude, $geolocation.coords.longitude);
    googleMapInstance?.setCenter(p);
    marker.setPosition(p);
    circle.setCenter(p);
    circle.setRadius($geolocation.coords.accuracy);
    googleMapInstance?.setZoom(getZoom($geolocation.coords.accuracy));
    try {
        const geocoderResponse = await (new (await $googleMaps.lib.geocoding).Geocoder()).geocode({ location: p });
        address.value = geocoderResponse.results[0]?.formatted_address;
    }
    catch (e) {
        console.error(e);
    }
}

function getZoom(accuracy: number): number {
    if (accuracy < 1) {
        return 20.5;
    }
    return 20.5 - (Math.log2(accuracy) / 1.4) - Math.log10(accuracy / 10);
}

function onClick() {
    isShowing.value = true;
    nextTick(onShow);
}

function onShow() {
    show.value = true;
    nextTick(() => {
        initMap();
        watcher = watch(() => [$geolocation.coords.latitude, $geolocation.coords.longitude], updateLocation);
    });
}

function onHide() {
    show.value = false;
    marker?.setMap(null);
    circle?.setMap(null);
    watcher?.();
    googleMapInstance = marker = circle = null;
    address.value = '';
}
function onHidden() {
    isShowing.value = false;
}
</script>

<template>
    <button type="button" @click.prevent="onClick">
        <slot>Test Device GPS</slot>
        <BModal
            v-if="isShowing"
            v-model="show"
            title="GPS Working"
            title-tag="h2"
            title-class="h4 text-success"
            ok-only
            ok-title="Close"
            ok-variant="outline-dark"
            @hide="onHide"
            @hidden="onHidden"
        >
            <div class="d-flex flex-column gap-2">
                <p class="text-center">Your device appears to be capable of clocking in and out via GPS. You can verify accuracy below.</p>
                <p class="h6">Current Position</p>
                <div id="test-gps-map" ref="mapEl"></div>
                <p v-if="address" class="text-center">{{ address }}</p>
                <div class="row">
                    <div class="col-5 text-end">
                        <strong>Latitude:</strong>
                    </div>
                    <div class="col">{{ $geolocation.coords.latitude.toFixed(7) }}</div>
                </div>
                <div class="row">
                    <div class="col-5 text-end">
                        <strong>Longitude:</strong>
                    </div>
                    <div class="col">{{ $geolocation.coords.longitude.toFixed(7) }}</div>
                </div>
                <div class="row">
                    <div class="col-5 text-end">
                        <strong>Accuracy:</strong>
                    </div>
                    <div class="col">{{ $geolocation.coords.accuracy.toFixed(2) }}</div>
                </div>
            </div>
        </BModal>
    </button>
</template>

<style scoped lang="scss">
#test-gps-map {
    aspect-ratio: 1.5;
    margin-bottom: 1rem;
}
</style>
